import React from 'react'
import { motion,useAnimation  } from 'framer-motion'; // Import motion from Framer Motion
import homeanimation from "../images/Leadsmobil.png"
import { useState } from 'react';

import progess from "../images/Progress.png"
import profile from "../images/profile.png"
import Chame from '../pages/Chamelon/Chame';
import { Link } from 'react-router-dom';
function HeroSection() {
    const [isHovered, setIsHovered] = useState(false);
  
   
    return (
        <>
            <div className='sectionhero'>
                <div className='centered_content'>
                    <div className="coverofrocker centered_content">
                        <div className='skyrocket'>Skyrocket Your Sales 🚀<br></br>
                            <span className='datasoft'>With Our Insurance Data Software</span>
                        </div>
                        <p className='explainationtext'>Unleash explosive growth for your insurance agency with our proven transportation sales CRM, extensive USDOT & motor carrier analysis, and cutting-edge B2B marketing tools.</p>
                    <Link to='/registration'>  <button className='freebtn border-0 zoom-in'> Start Now (30 Day Free Trial)</button></Link>  
                        <div className='feature_div'>
                            <div className='moneyback d-flex align-items-center '>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <path d="M5.96094 22.0391C4.95469 21.0328 5.62188 18.9219 5.10781 17.6859C4.59375 16.45 2.625 15.3672 2.625 14C2.625 12.6328 4.57188 11.5938 5.10781 10.3141C5.64375 9.03438 4.95469 6.96719 5.96094 5.96094C6.96719 4.95469 9.07813 5.62188 10.3141 5.10781C11.55 4.59375 12.6328 2.625 14 2.625C15.3672 2.625 16.4063 4.57188 17.6859 5.10781C18.9656 5.64375 21.0328 4.95469 22.0391 5.96094C23.0453 6.96719 22.3781 9.07813 22.8922 10.3141C23.4063 11.55 25.375 12.6328 25.375 14C25.375 15.3672 23.4281 16.4063 22.8922 17.6859C22.3563 18.9656 23.0453 21.0328 22.0391 22.0391C21.0328 23.0453 18.9219 22.3781 17.6859 22.8922C16.45 23.4063 15.3672 25.375 14 25.375C12.6328 25.375 11.5938 23.4281 10.3141 22.8922C9.03438 22.3563 6.96719 23.0453 5.96094 22.0391Z" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M18.8125 11.375L12.3922 17.5L9.1875 14.4375" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                60 Day Money Back Guarantee
                            </div>
                            <div className='moneyback d-flex align-items-center '>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <path d="M5.96094 22.0391C4.95469 21.0328 5.62188 18.9219 5.10781 17.6859C4.59375 16.45 2.625 15.3672 2.625 14C2.625 12.6328 4.57188 11.5938 5.10781 10.3141C5.64375 9.03438 4.95469 6.96719 5.96094 5.96094C6.96719 4.95469 9.07813 5.62188 10.3141 5.10781C11.55 4.59375 12.6328 2.625 14 2.625C15.3672 2.625 16.4063 4.57188 17.6859 5.10781C18.9656 5.64375 21.0328 4.95469 22.0391 5.96094C23.0453 6.96719 22.3781 9.07813 22.8922 10.3141C23.4063 11.55 25.375 12.6328 25.375 14C25.375 15.3672 23.4281 16.4063 22.8922 17.6859C22.3563 18.9656 23.0453 21.0328 22.0391 22.0391C21.0328 23.0453 18.9219 22.3781 17.6859 22.8922C16.45 23.4063 15.3672 25.375 14 25.375C12.6328 25.375 11.5938 23.4281 10.3141 22.8922C9.03438 22.3563 6.96719 23.0453 5.96094 22.0391Z" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M18.8125 11.375L12.3922 17.5L9.1875 14.4375" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                10X Your Sales Through Our Data
                            </div>
                        </div>
                    </div>

                </div>
                <div className='fordesk'>
                    <div className='homeimage'>
                     <div className='scrollerimage'
                    >
                         <Chame
                         
                        />
                     </div>
                       
                        <div className='coverofhomecards'>
                            <div
                                className='callcardsreview'> </div>
                        </div>
                    </div></div>
                <div className='formobile'>
                    <div className='homeimmage'>
                        <img className='homeani' src={homeanimation} alt='image of mobile'></img>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HeroSection