import footerimage from "../images/footerimage.png"
import brandimage from "../images/Brand.svg"
import footerimage2 from "../images/footerimg2.png"
import { Link } from "react-router-dom";
function Footer() {
    return (
        <>
            {/* <div className="abovetofooter">

            </div> */}
            <div className="footerof">
                <div className="freestart">
                    <div className="firstdiv">
                        <div className="greenbox">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M7 7V13.8875C7 18.85 10.975 22.9625 15.9375 23C17.1246 23.0082 18.3017 22.7815 19.4008 22.3329C20.5 21.8843 21.4995 21.2227 22.3419 20.3862C23.1843 19.5496 23.8528 18.5547 24.309 17.4586C24.7652 16.3626 25 15.1872 25 14V7C25 6.73478 24.8946 6.48043 24.7071 6.29289C24.5196 6.10536 24.2652 6 24 6H8C7.73478 6 7.48043 6.10536 7.29289 6.29289C7.10536 6.48043 7 6.73478 7 7Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12 28H20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M16 23V28" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M24.7749 16H25.9999C27.0608 16 28.0782 15.5786 28.8283 14.8284C29.5785 14.0783 29.9999 13.0609 29.9999 12V10C29.9999 9.73478 29.8945 9.48043 29.707 9.29289C29.5195 9.10536 29.2651 9 28.9999 9H24.9999" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.2498 16H5.9873C4.92644 16 3.90902 15.5786 3.15888 14.8284C2.40873 14.0783 1.9873 13.0609 1.9873 12V10C1.9873 9.73478 2.09266 9.48043 2.2802 9.29289C2.46773 9.10536 2.72209 9 2.9873 9H6.9873" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <div className="textboxfooter">
                            <div className="texthead zoom-in">
                                Get started for free
                            </div>
                            <div className="footer_para">
                            Start today, completely free, and see how we can revolutionize the growth of your agency.                            </div>
                        </div>
                        <div className="darkbluebox">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M16 22C21.5228 22 26 17.5228 26 12C26 6.47715 21.5228 2 16 2C10.4772 2 6 6.47715 6 12C6 17.5228 10.4772 22 16 22Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M16 18C19.3137 18 22 15.3137 22 12C22 8.68629 19.3137 6 16 6C12.6863 6 10 8.68629 10 12C10 15.3137 12.6863 18 16 18Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22 20V30L16 27L10 30V20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </div>
                    <div className="firstdiv">
                        <div className="purplebox">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M4 13H10V26H4C3.73478 26 3.48043 25.8946 3.29289 25.7071C3.10536 25.5196 3 25.2652 3 25V14C3 13.7348 3.10536 13.4804 3.29289 13.2929C3.48043 13.1054 3.73478 13 4 13V13Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M10 13L15 3C16.0609 3 17.0783 3.42143 17.8284 4.17157C18.5786 4.92172 19 5.93913 19 7V10H26.7375C27.0211 9.9993 27.3015 10.0593 27.56 10.176C27.8185 10.2926 28.049 10.4632 28.2361 10.6764C28.4232 10.8895 28.5625 11.1402 28.6447 11.4116C28.7268 11.683 28.75 11.9689 28.7125 12.25L27.2125 24.25C27.1518 24.7317 26.918 25.1749 26.5546 25.4969C26.1912 25.8189 25.723 25.9977 25.2375 26H10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <div className="textboxfooter">
                           <Link to='/registration'> <div className="freebutton zoom-in">Start for free</div></Link>

                        </div>
                        <div className="bluebox2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M16.55 23.8375L22.85 27.8375C23.6625 28.35 24.6625 27.5875 24.425 26.65L22.6 19.475C22.5506 19.2762 22.5585 19.0674 22.6226 18.8728C22.6868 18.6781 22.8046 18.5056 22.9625 18.375L28.6125 13.6625C29.35 13.05 28.975 11.8125 28.0125 11.75L20.6375 11.275C20.4363 11.2633 20.2428 11.1933 20.0808 11.0734C19.9187 10.9535 19.7951 10.789 19.725 10.6L16.975 3.67503C16.9022 3.47491 16.7696 3.30204 16.5952 3.17988C16.4207 3.05772 16.2129 2.99219 16 2.99219C15.787 2.99219 15.5793 3.05772 15.4048 3.17988C15.2304 3.30204 15.0978 3.47491 15.025 3.67503L12.275 10.6C12.2049 10.789 12.0813 10.9535 11.9192 11.0734C11.7572 11.1933 11.5637 11.2633 11.3625 11.275L3.98749 11.75C3.02499 11.8125 2.64999 13.05 3.38749 13.6625L9.0375 18.375C9.19541 18.5056 9.31323 18.6781 9.37736 18.8728C9.4415 19.0674 9.44934 19.2762 9.39999 19.475L7.71249 26.125C7.42499 27.25 8.62499 28.1625 9.58749 27.55L15.45 23.8375C15.6144 23.733 15.8052 23.6775 16 23.6775C16.1948 23.6775 16.3856 23.733 16.55 23.8375V23.8375Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </div>
                    <div className="footerimage">
                        <img src={footerimage} alt="footerimage"></img>
                    </div>
                </div>
                <div className="freestart2 bg-white d-none">
                    <div className="textboxfooter">
                        <div className="texthead zoom-in">
                            Get started for free
                        </div>
                        <div className="footer_para">
                        Boost your insurance agency's growth with our transportation sales CRM, USDOT analysis, and B2B marketing tools.                        </div>
                    </div>
                    <div className="textboxfooter">
                    <Link to='/registration'> <div className="freebutton zoom-in">Start for free</div></Link>
                    </div>
                    <div className="d-flex svgboxs justify-content-center gap-4 py-5">
                        <div className="greenbox">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M7 7V13.8875C7 18.85 10.975 22.9625 15.9375 23C17.1246 23.0082 18.3017 22.7815 19.4008 22.3329C20.5 21.8843 21.4995 21.2227 22.3419 20.3862C23.1843 19.5496 23.8528 18.5547 24.309 17.4586C24.7652 16.3626 25 15.1872 25 14V7C25 6.73478 24.8946 6.48043 24.7071 6.29289C24.5196 6.10536 24.2652 6 24 6H8C7.73478 6 7.48043 6.10536 7.29289 6.29289C7.10536 6.48043 7 6.73478 7 7Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12 28H20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M16 23V28" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M24.7749 16H25.9999C27.0608 16 28.0782 15.5786 28.8283 14.8284C29.5785 14.0783 29.9999 13.0609 29.9999 12V10C29.9999 9.73478 29.8945 9.48043 29.707 9.29289C29.5195 9.10536 29.2651 9 28.9999 9H24.9999" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.2498 16H5.9873C4.92644 16 3.90902 15.5786 3.15888 14.8284C2.40873 14.0783 1.9873 13.0609 1.9873 12V10C1.9873 9.73478 2.09266 9.48043 2.2802 9.29289C2.46773 9.10536 2.72209 9 2.9873 9H6.9873" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <div className="darkbluebox">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M16 22C21.5228 22 26 17.5228 26 12C26 6.47715 21.5228 2 16 2C10.4772 2 6 6.47715 6 12C6 17.5228 10.4772 22 16 22Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M16 18C19.3137 18 22 15.3137 22 12C22 8.68629 19.3137 6 16 6C12.6863 6 10 8.68629 10 12C10 15.3137 12.6863 18 16 18Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22 20V30L16 27L10 30V20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <div className="bluebox2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M16.55 23.8375L22.85 27.8375C23.6625 28.35 24.6625 27.5875 24.425 26.65L22.6 19.475C22.5506 19.2762 22.5585 19.0674 22.6226 18.8728C22.6868 18.6781 22.8046 18.5056 22.9625 18.375L28.6125 13.6625C29.35 13.05 28.975 11.8125 28.0125 11.75L20.6375 11.275C20.4363 11.2633 20.2428 11.1933 20.0808 11.0734C19.9187 10.9535 19.7951 10.789 19.725 10.6L16.975 3.67503C16.9022 3.47491 16.7696 3.30204 16.5952 3.17988C16.4207 3.05772 16.2129 2.99219 16 2.99219C15.787 2.99219 15.5793 3.05772 15.4048 3.17988C15.2304 3.30204 15.0978 3.47491 15.025 3.67503L12.275 10.6C12.2049 10.789 12.0813 10.9535 11.9192 11.0734C11.7572 11.1933 11.5637 11.2633 11.3625 11.275L3.98749 11.75C3.02499 11.8125 2.64999 13.05 3.38749 13.6625L9.0375 18.375C9.19541 18.5056 9.31323 18.6781 9.37736 18.8728C9.4415 19.0674 9.44934 19.2762 9.39999 19.475L7.71249 26.125C7.42499 27.25 8.62499 28.1625 9.58749 27.55L15.45 23.8375C15.6144 23.733 15.8052 23.6775 16 23.6775C16.1948 23.6775 16.3856 23.733 16.55 23.8375V23.8375Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <div className="purplebox">

                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M4 13H10V26H4C3.73478 26 3.48043 25.8946 3.29289 25.7071C3.10536 25.5196 3 25.2652 3 25V14C3 13.7348 3.10536 13.4804 3.29289 13.2929C3.48043 13.1054 3.73478 13 4 13V13Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M10 13L15 3C16.0609 3 17.0783 3.42143 17.8284 4.17157C18.5786 4.92172 19 5.93913 19 7V10H26.7375C27.0211 9.9993 27.3015 10.0593 27.56 10.176C27.8185 10.2926 28.049 10.4632 28.2361 10.6764C28.4232 10.8895 28.5625 11.1402 28.6447 11.4116C28.7268 11.683 28.75 11.9689 28.7125 12.25L27.2125 24.25C27.1518 24.7317 26.918 25.1749 26.5546 25.4969C26.1912 25.8189 25.723 25.9977 25.2375 26H10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>



                    </div>
                    <div className="footerimage2">
                        <img src={footerimage2} alt="footerimage" width="100%"></img>
                    </div>
                </div>
                <div className="realfooter">
                    <div className="logomain " >
                        <img src={brandimage} alt="image" width="176px" height='34px'></img>

                        <div className=" d-none  footbtn2 py-5" role="search">

                        <Link to='/registration'>   <button className="getsta" type="submit">
                                Get started
                            </button></Link> 
                            <a href="https://app.freightinsdata.com/login" className="linkbtn hoverlink" >
                                Log in
                            </a>
                        </div>

                        <p className="copyright">Copyright @ <br></br>
                        Freight Insurance Data, LLC</p>
                    </div>
                    <div className="coverofalllinks">


                    <div className="alllinks">
                            <div className="home">
                                <Link to='/'><div className="headhome hoverlink">
                                    Home
                                </div></Link>
                                <ul>
                                <Link to='/aboutus'>   <li className="hoverlink">
                                        About us
                                    </li></Link>
                                <Link to='/products'>  <li className="hoverlink">
                                        Products
                                    </li></Link>
                                    <Link to='/pricing'>   <li className="hoverlink">
                                        Pricing
                                    </li></Link>
                                    <Link to='/faq'> <li className="hoverlink">
                                        FAQs
                                    </li></Link> 
                                  
                              
                                  
                                  
                                </ul>
                            </div>
                        </div>
                        <div className="alllinks">
                            <div className="home">
                            <Link >   <div className="headhome hoverlink">
                            Freight Ins Data
                                </div></Link> 
                                <ul>
                                    <li>
                                      
                                    </li>
       <li>
                                        Terms & Conditions
                                    </li>
                                    <li>
                                        Privacy Policy
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="alllinks">
                            <div className="home">
                                <div className="headhome">
                                    Contact
                                </div>
                                <ul>
                                    <li>
                                    <div>    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M4.375 18.125H15.625" stroke="white" stroke-opacity="0.75" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M10 10.625C11.3807 10.625 12.5 9.50571 12.5 8.125C12.5 6.74429 11.3807 5.625 10 5.625C8.61929 5.625 7.5 6.74429 7.5 8.125C7.5 9.50571 8.61929 10.625 10 10.625Z" stroke="white" stroke-opacity="0.75" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16.25 8.125C16.25 13.75 10 18.125 10 18.125C10 18.125 3.75 13.75 3.75 8.125C3.75 6.4674 4.40848 4.87769 5.58058 3.70558C6.75269 2.53348 8.3424 1.875 10 1.875C11.6576 1.875 13.2473 2.53348 14.4194 3.70558C15.5915 4.87769 16.25 6.4674 16.25 8.125V8.125Z" stroke="white" stroke-opacity="0.75" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg> </div>   <a href="https://maps.app.goo.gl/JgkAjeSdHk62i6rX8" className="hoverlink text-light">  66 Waverley Dr. Ste 630, Frederick, MD 21702 </a> 
                                    </li>
                                    <a href="mailto:support@freightinsdata.com" className="hoverlink">     <li>
                                   <div>    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M3 9V18.75C3 18.9489 3.07902 19.1397 3.21967 19.2803C3.36032 19.421 3.55109 19.5 3.75 19.5H20.25C20.4489 19.5 20.6397 19.421 20.7803 19.2803C20.921 19.1397 21 18.9489 21 18.75V9L12 3L3 9Z" stroke="white" stroke-opacity="0.75" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M10.3594 14.25L3.23438 19.2844" stroke="white" stroke-opacity="0.75" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M20.7656 19.2844L13.6406 14.25" stroke="white" stroke-opacity="0.75" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M21 9L13.6406 14.25H10.3594L3 9" stroke="white" stroke-opacity="0.75" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg> </div>support@freightinsdata.com
                                    </li></a>
                                    <a href="tel:+1234567890">  <li>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                <path d="M7.94922 10.725C8.65667 12.1859 9.83802 13.3642 11.3008 14.0679C11.4087 14.119 11.528 14.1411 11.6471 14.1321C11.7661 14.1231 11.8807 14.0832 11.9797 14.0164L14.1281 12.5812C14.223 12.5168 14.3327 12.4776 14.4469 12.467C14.5612 12.4565 14.6762 12.4751 14.7812 12.5211L18.8031 14.2484C18.9406 14.3056 19.0554 14.4064 19.13 14.5353C19.2045 14.6642 19.2346 14.814 19.2156 14.9617C19.0882 15.9566 18.6025 16.871 17.8496 17.5338C17.0967 18.1965 16.1281 18.5622 15.125 18.5625C12.0253 18.5625 9.05252 17.3311 6.86069 15.1393C4.66886 12.9474 3.4375 9.97468 3.4375 6.87497C3.43773 5.8719 3.80343 4.90329 4.46619 4.15037C5.12895 3.39745 6.04335 2.91181 7.03828 2.78434C7.18596 2.76537 7.33577 2.79547 7.46466 2.87001C7.59354 2.94455 7.69435 3.05939 7.75156 3.19684L9.47891 7.22731C9.52383 7.33073 9.54262 7.44362 9.53363 7.55602C9.52463 7.66841 9.48813 7.77687 9.42734 7.87184L7.99219 10.0547C7.92829 10.1534 7.89079 10.2668 7.88327 10.3842C7.87575 10.5016 7.89845 10.6189 7.94922 10.725V10.725Z" stroke="white" stroke-opacity="0.75" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg> </div>  301-591-6550
                                    </li></a> 

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="d-flex align-items-center gap-4 footbtn" role="search">
                            <a href="https://app.freightinsdata.com/login" className="linkbtn hoverlink" >
                                Log in
                            </a>
                           <Link to='/registration'><button className="getsta" type="submit">
                                Get started
                            </button></Link> 
                        </div>
                        <div className="socialicons">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z" stroke="white" stroke-width="2" stroke-miterlimit="10" />
                                <path d="M21.5 4.5H10.5C7.18629 4.5 4.5 7.18629 4.5 10.5V21.5C4.5 24.8137 7.18629 27.5 10.5 27.5H21.5C24.8137 27.5 27.5 24.8137 27.5 21.5V10.5C27.5 7.18629 24.8137 4.5 21.5 4.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22.5 11C23.3284 11 24 10.3284 24 9.5C24 8.67157 23.3284 8 22.5 8C21.6716 8 21 8.67157 21 9.5C21 10.3284 21.6716 11 22.5 11Z" fill="white" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M21 11H19C18.6056 10.9984 18.2147 11.0748 17.85 11.225C17.4853 11.3752 17.1539 11.5961 16.875 11.875C16.5961 12.1539 16.3752 12.4853 16.225 12.85C16.0748 13.2147 15.9984 13.6056 16 14V28" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12 18H20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M20 16L14 12V20L20 16Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M3 15.9999C3 19.7249 3.3875 21.8999 3.675 23.0249C3.75427 23.3301 3.90418 23.6125 4.11262 23.8491C4.32106 24.0858 4.58221 24.2702 4.875 24.3874C9.0625 25.9874 16 25.9499 16 25.9499C16 25.9499 22.9375 25.9874 27.125 24.3874C27.4178 24.2702 27.6789 24.0858 27.8874 23.8491C28.0958 23.6125 28.2457 23.3301 28.325 23.0249C28.6125 21.8999 29 19.7249 29 15.9999C29 12.2749 28.6125 10.0999 28.325 8.97488C28.2457 8.66963 28.0958 8.38729 27.8874 8.15062C27.6789 7.91395 27.4178 7.72958 27.125 7.61238C22.9375 6.01238 16 6.04988 16 6.04988C16 6.04988 9.0625 6.01238 4.875 7.61238C4.58221 7.72958 4.32106 7.91395 4.11262 8.15062C3.90418 8.38729 3.75427 8.66963 3.675 8.97488C3.3875 10.0999 3 12.2749 3 15.9999Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </div>


                </div>
                <div className="">
                    <div className="copyright2 text-white text-center d-none py-5">Copyright @ freight <br /> insurance data 2024</div>
                </div>
            </div>

        </>
    )
}
export default Footer;