import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Spin } from "antd";
function Carouselhome({ handelselection, activeButton }) {
  console.log(activeButton)
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 6000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 0,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const navigation = useNavigate()
  const onslected = (formData) => {
    navigation("/registration")
  }
  const [yearlyPlans, setYearlyPlans] = useState([]);
  const [monthlyPlans, setMonthlyPlans] = useState([]);
  const [isloading, setIsloading] = useState(true)
  useEffect(() => {
    setIsloading(true)
    const fetchPlans = async () => {
      try {
        const response = await axios.post("https://app.freightinsdata.com/api/subscription_plans", {
          action: "retrieve_plans",
        });

        const plans = response.data.plans;

        // Separate plans into Yearly and Monthly
        const yearly = plans.filter(plan => plan.plan_type === "Yearly");
        const monthly = plans.filter(plan => plan.plan_type === "Monthly");

        setYearlyPlans(yearly);
        setMonthlyPlans(monthly);
      } catch (error) {
        console.error("Error fetching subscription plans:", error);
      } finally {
        setIsloading(false)

      }
    };

    fetchPlans();
  }, []);
  return (
    <>

      <div className="toperforslider"></div>

      <div className="slider-container" style={{ overflowX: 'hidden' }}>
        {isloading ? (<div className="d-flex justify-content-center align-items-center" style={{ height: "700px" }}>
          <Spin fullscreen={true} />
        </div>) : (
          activeButton === 'Monthly' ? (
            <div className="extracover">
              <Slider {...settings}>
                {
                  monthlyPlans.map((planm) => {
                    return (
                      <>
                        <div>
                          <div className="coverofprice mb-5">
                            <div className="poinsshownmain2">
                              <div className="starcontent">
                                <div className="bluebox">
                                  {
                                    planm.plan_name === "Starter" ? (
                                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path d="M16 3V23.675" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M27.5252 14.575L28.6127 13.6625C29.3502 13.05 28.9752 11.8125 28.0127 11.75L26.5752 11.6625" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M23.0124 21.1124L22.5999 19.475C22.5505 19.2761 22.5584 19.0673 22.6225 18.8727C22.6866 18.6781 22.8045 18.5055 22.9624 18.375L24.2124 17.325" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M22.275 11.375L20.6375 11.275C20.4363 11.2633 20.2428 11.1933 20.0808 11.0734C19.9187 10.9535 19.7951 10.789 19.725 10.6L19.0875 9.00003L16.975 3.67503C16.9022 3.47491 16.7696 3.30204 16.5952 3.17988C16.4207 3.05772 16.2129 2.99219 16 2.99219C15.787 2.99219 15.5793 3.05772 15.4048 3.17988C15.2304 3.30204 15.0978 3.47491 15.025 3.67503L12.275 10.6C12.2049 10.789 12.0813 10.9535 11.9192 11.0734C11.7572 11.1933 11.5637 11.2633 11.3625 11.275L3.98749 11.75C3.02499 11.8125 2.64999 13.05 3.38749 13.6625L9.0375 18.375C9.19541 18.5056 9.31323 18.6781 9.37736 18.8728C9.4415 19.0674 9.44934 19.2762 9.39999 19.475L7.71249 26.125C7.42499 27.25 8.62499 28.1625 9.58749 27.55L15.45 23.8375C15.6144 23.733 15.8052 23.6775 16 23.6775C16.1948 23.6775 16.3856 23.733 16.55 23.8375L17.9875 24.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M21.625 27.0625L22.85 27.8375C23.6625 28.35 24.6625 27.5875 24.425 26.65L24.075 25.2875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      </svg>
                                    ) : planm.plan_name === "Business" ? (
                                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path d="M16.551 23.8375L22.851 27.8375C23.6635 28.35 24.6635 27.5875 24.426 26.65L22.601 19.475C22.5516 19.2762 22.5595 19.0674 22.6236 18.8728C22.6877 18.6781 22.8056 18.5056 22.9635 18.375L28.6135 13.6625C29.351 13.05 28.976 11.8125 28.0135 11.75L20.6385 11.275C20.4372 11.2633 20.2438 11.1933 20.0817 11.0734C19.9197 10.9535 19.7961 10.789 19.726 10.6L16.976 3.67503C16.9032 3.47491 16.7706 3.30204 16.5961 3.17988C16.4217 3.05772 16.2139 2.99219 16.001 2.99219C15.788 2.99219 15.5802 3.05772 15.4058 3.17988C15.2314 3.30204 15.0988 3.47491 15.026 3.67503L12.276 10.6C12.2059 10.789 12.0823 10.9535 11.9202 11.0734C11.7582 11.1933 11.5647 11.2633 11.3635 11.275L3.98847 11.75C3.02597 11.8125 2.65097 13.05 3.38847 13.6625L9.03847 18.375C9.19639 18.5056 9.3142 18.6781 9.37834 18.8728C9.44247 19.0674 9.45032 19.2762 9.40097 19.475L7.71347 26.125C7.42597 27.25 8.62597 28.1625 9.58847 27.55L15.451 23.8375C15.6154 23.733 15.8062 23.6775 16.001 23.6775C16.1958 23.6775 16.3866 23.733 16.551 23.8375V23.8375Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      </svg>
                                    ) : planm.plan_name === "Enterprise" ? (
                                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path d="M7 7V13.8875C7 18.85 10.975 22.9625 15.9375 23C17.1246 23.0082 18.3017 22.7815 19.4008 22.3329C20.5 21.8843 21.4995 21.2227 22.3419 20.3862C23.1843 19.5496 23.8528 18.5547 24.309 17.4586C24.7652 16.3626 25 15.1872 25 14V7C25 6.73478 24.8946 6.48043 24.7071 6.29289C24.5196 6.10536 24.2652 6 24 6H8C7.73478 6 7.48043 6.10536 7.29289 6.29289C7.10536 6.48043 7 6.73478 7 7Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M12 28H20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16 23V28" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M24.7754 16H26.0004C27.0613 16 28.0787 15.5786 28.8288 14.8284C29.579 14.0783 30.0004 13.0609 30.0004 12V10C30.0004 9.73478 29.895 9.48043 29.7075 9.29289C29.52 9.10536 29.2656 9 29.0004 9H25.0004" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M7.25078 16H5.98828C4.92742 16 3.91 15.5786 3.15985 14.8284C2.40971 14.0783 1.98828 13.0609 1.98828 12V10C1.98828 9.73478 2.09364 9.48043 2.28117 9.29289C2.46871 9.10536 2.72306 9 2.98828 9H6.98828" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      </svg>
                                    ) : null
                                  }


                                </div>
                              </div>
                              <div className="starttext">
                                {planm.plan_name}
                              </div>
                              <div className="priceofplan">
                                ${planm.price}<span className="month">/mo</span>
                              </div>
                              <div className="getbtn" onClick={() => { onslected(planm.plan_id) }} >
                                Get started
                              </div>
                            </div>
                            <div className="poinsshownmain">
                              <div className="pointsshown">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                  <path d="M5.96094 22.0391C4.95469 21.0328 5.62188 18.9219 5.10781 17.6859C4.59375 16.45 2.625 15.3672 2.625 14C2.625 12.6328 4.57188 11.5938 5.10781 10.3141C5.64375 9.03438 4.95469 6.96719 5.96094 5.96094C6.96719 4.95469 9.07813 5.62188 10.3141 5.10781C11.55 4.59375 12.6328 2.625 14 2.625C15.3672 2.625 16.4063 4.57188 17.6859 5.10781C18.9656 5.64375 21.0328 4.95469 22.0391 5.96094C23.0453 6.96719 22.3781 9.07813 22.8922 10.3141C23.4063 11.55 25.375 12.6328 25.375 14C25.375 15.3672 23.4281 16.4063 22.8922 17.6859C22.3563 18.9656 23.0453 21.0328 22.0391 22.0391C21.0328 23.0453 18.9219 22.3781 17.6859 22.8922C16.45 23.4063 15.3672 25.375 14 25.375C12.6328 25.375 11.5938 23.4281 10.3141 22.8922C9.03438 22.3563 6.96719 23.0453 5.96094 22.0391Z" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M18.8125 11.375L12.3922 17.5L9.1875 14.4375" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                {
                                  planm.plan_name === "Starter" ? (
                                    <>
                                      {planm.number_of_users} User Limit
                                    </>
                                  ) : null
                                }
                                {
                                  planm.plan_name === "Business" ? (
                                    <>
                                      Up to {planm.number_of_users} Users
                                    </>
                                  ) : null
                                }
                                {
                                  planm.plan_name === "Enterprise" ? (
                                    <>
                                      Unlimited Users + Everything in Business
                                    </>
                                  ) : null
                                }
                              </div>
                              <div className="pointsshown">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                  <path d="M5.96094 22.0391C4.95469 21.0328 5.62188 18.9219 5.10781 17.6859C4.59375 16.45 2.625 15.3672 2.625 14C2.625 12.6328 4.57188 11.5938 5.10781 10.3141C5.64375 9.03438 4.95469 6.96719 5.96094 5.96094C6.96719 4.95469 9.07813 5.62188 10.3141 5.10781C11.55 4.59375 12.6328 2.625 14 2.625C15.3672 2.625 16.4063 4.57188 17.6859 5.10781C18.9656 5.64375 21.0328 4.95469 22.0391 5.96094C23.0453 6.96719 22.3781 9.07813 22.8922 10.3141C23.4063 11.55 25.375 12.6328 25.375 14C25.375 15.3672 23.4281 16.4063 22.8922 17.6859C22.3563 18.9656 23.0453 21.0328 22.0391 22.0391C21.0328 23.0453 18.9219 22.3781 17.6859 22.8922C16.45 23.4063 15.3672 25.375 14 25.375C12.6328 25.375 11.5938 23.4281 10.3141 22.8922C9.03438 22.3563 6.96719 23.0453 5.96094 22.0391Z" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M18.8125 11.375L12.3922 17.5L9.1875 14.4375" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg> {planm.deep_dive_reports === -1 ? "Deep Dive & Chameleon Carrier (Unlimited)" : (<> Deep Dive {planm.deep_dive_reports} Reports per month</>)}

                              </div>
                              <div className="pointsshown">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                  <path d="M5.96094 22.0391C4.95469 21.0328 5.62188 18.9219 5.10781 17.6859C4.59375 16.45 2.625 15.3672 2.625 14C2.625 12.6328 4.57188 11.5938 5.10781 10.3141C5.64375 9.03438 4.95469 6.96719 5.96094 5.96094C6.96719 4.95469 9.07813 5.62188 10.3141 5.10781C11.55 4.59375 12.6328 2.625 14 2.625C15.3672 2.625 16.4063 4.57188 17.6859 5.10781C18.9656 5.64375 21.0328 4.95469 22.0391 5.96094C23.0453 6.96719 22.3781 9.07813 22.8922 10.3141C23.4063 11.55 25.375 12.6328 25.375 14C25.375 15.3672 23.4281 16.4063 22.8922 17.6859C22.3563 18.9656 23.0453 21.0328 22.0391 22.0391C21.0328 23.0453 18.9219 22.3781 17.6859 22.8922C16.45 23.4063 15.3672 25.375 14 25.375C12.6328 25.375 11.5938 23.4281 10.3141 22.8922C9.03438 22.3563 6.96719 23.0453 5.96094 22.0391Z" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M18.8125 11.375L12.3922 17.5L9.1875 14.4375" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg> {planm.chameleon_carrier_searches === -1 ? "Leads Database (Nationwide) " : (<>Chameleon Carrier  {planm.chameleon_carrier_searches} Searches per month</>)}
                              </div>
                              <div className="pointsshown">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                  <path d="M5.96094 22.0391C4.95469 21.0328 5.62188 18.9219 5.10781 17.6859C4.59375 16.45 2.625 15.3672 2.625 14C2.625 12.6328 4.57188 11.5938 5.10781 10.3141C5.64375 9.03438 4.95469 6.96719 5.96094 5.96094C6.96719 4.95469 9.07813 5.62188 10.3141 5.10781C11.55 4.59375 12.6328 2.625 14 2.625C15.3672 2.625 16.4063 4.57188 17.6859 5.10781C18.9656 5.64375 21.0328 4.95469 22.0391 5.96094C23.0453 6.96719 22.3781 9.07813 22.8922 10.3141C23.4063 11.55 25.375 12.6328 25.375 14C25.375 15.3672 23.4281 16.4063 22.8922 17.6859C22.3563 18.9656 23.0453 21.0328 22.0391 22.0391C21.0328 23.0453 18.9219 22.3781 17.6859 22.8922C16.45 23.4063 15.3672 25.375 14 25.375C12.6328 25.375 11.5938 23.4281 10.3141 22.8922C9.03438 22.3563 6.96719 23.0453 5.96094 22.0391Z" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M18.8125 11.375L12.3922 17.5L9.1875 14.4375" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg> {planm.number_of_states === -1 ? "Insurance Carrier Database (Nationwide)" : (<> Leads Database  {planm.number_of_states} states</>)}
                              </div>

                            </div>
                            {
  planm.plan_name === 'Starter' ?(
    <div className="undertext ">* {planm.free_trial_days} Day Free Trial Of Enterprise Plan</div>

  ):(
    <div className="undertext">* {planm.free_trial_days} Day Free Trial</div>

  )
}

                          </div>
                        </div>
                      </>

                    )
                  })
                }

              </Slider>
            </div>
          ) : (
            <div className="extracover">
              <Slider {...settings}>
                {
                  yearlyPlans.map((planm) => {
                    return (
                      <>
                        <div>
                          <div className="coverofprice mb-5">
                            <div className="poinsshownmain2">
                              <div className="starcontent">
                                <div className="bluebox">
                                  {
                                    planm.plan_name === "Starter" ? (
                                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path d="M16 3V23.675" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M27.5252 14.575L28.6127 13.6625C29.3502 13.05 28.9752 11.8125 28.0127 11.75L26.5752 11.6625" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M23.0124 21.1124L22.5999 19.475C22.5505 19.2761 22.5584 19.0673 22.6225 18.8727C22.6866 18.6781 22.8045 18.5055 22.9624 18.375L24.2124 17.325" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M22.275 11.375L20.6375 11.275C20.4363 11.2633 20.2428 11.1933 20.0808 11.0734C19.9187 10.9535 19.7951 10.789 19.725 10.6L19.0875 9.00003L16.975 3.67503C16.9022 3.47491 16.7696 3.30204 16.5952 3.17988C16.4207 3.05772 16.2129 2.99219 16 2.99219C15.787 2.99219 15.5793 3.05772 15.4048 3.17988C15.2304 3.30204 15.0978 3.47491 15.025 3.67503L12.275 10.6C12.2049 10.789 12.0813 10.9535 11.9192 11.0734C11.7572 11.1933 11.5637 11.2633 11.3625 11.275L3.98749 11.75C3.02499 11.8125 2.64999 13.05 3.38749 13.6625L9.0375 18.375C9.19541 18.5056 9.31323 18.6781 9.37736 18.8728C9.4415 19.0674 9.44934 19.2762 9.39999 19.475L7.71249 26.125C7.42499 27.25 8.62499 28.1625 9.58749 27.55L15.45 23.8375C15.6144 23.733 15.8052 23.6775 16 23.6775C16.1948 23.6775 16.3856 23.733 16.55 23.8375L17.9875 24.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M21.625 27.0625L22.85 27.8375C23.6625 28.35 24.6625 27.5875 24.425 26.65L24.075 25.2875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      </svg>
                                    ) : planm.plan_name === "Business" ? (
                                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path d="M16.551 23.8375L22.851 27.8375C23.6635 28.35 24.6635 27.5875 24.426 26.65L22.601 19.475C22.5516 19.2762 22.5595 19.0674 22.6236 18.8728C22.6877 18.6781 22.8056 18.5056 22.9635 18.375L28.6135 13.6625C29.351 13.05 28.976 11.8125 28.0135 11.75L20.6385 11.275C20.4372 11.2633 20.2438 11.1933 20.0817 11.0734C19.9197 10.9535 19.7961 10.789 19.726 10.6L16.976 3.67503C16.9032 3.47491 16.7706 3.30204 16.5961 3.17988C16.4217 3.05772 16.2139 2.99219 16.001 2.99219C15.788 2.99219 15.5802 3.05772 15.4058 3.17988C15.2314 3.30204 15.0988 3.47491 15.026 3.67503L12.276 10.6C12.2059 10.789 12.0823 10.9535 11.9202 11.0734C11.7582 11.1933 11.5647 11.2633 11.3635 11.275L3.98847 11.75C3.02597 11.8125 2.65097 13.05 3.38847 13.6625L9.03847 18.375C9.19639 18.5056 9.3142 18.6781 9.37834 18.8728C9.44247 19.0674 9.45032 19.2762 9.40097 19.475L7.71347 26.125C7.42597 27.25 8.62597 28.1625 9.58847 27.55L15.451 23.8375C15.6154 23.733 15.8062 23.6775 16.001 23.6775C16.1958 23.6775 16.3866 23.733 16.551 23.8375V23.8375Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      </svg>
                                    ) : planm.plan_name === "Enterprise" ? (
                                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path d="M7 7V13.8875C7 18.85 10.975 22.9625 15.9375 23C17.1246 23.0082 18.3017 22.7815 19.4008 22.3329C20.5 21.8843 21.4995 21.2227 22.3419 20.3862C23.1843 19.5496 23.8528 18.5547 24.309 17.4586C24.7652 16.3626 25 15.1872 25 14V7C25 6.73478 24.8946 6.48043 24.7071 6.29289C24.5196 6.10536 24.2652 6 24 6H8C7.73478 6 7.48043 6.10536 7.29289 6.29289C7.10536 6.48043 7 6.73478 7 7Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M12 28H20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16 23V28" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M24.7754 16H26.0004C27.0613 16 28.0787 15.5786 28.8288 14.8284C29.579 14.0783 30.0004 13.0609 30.0004 12V10C30.0004 9.73478 29.895 9.48043 29.7075 9.29289C29.52 9.10536 29.2656 9 29.0004 9H25.0004" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M7.25078 16H5.98828C4.92742 16 3.91 15.5786 3.15985 14.8284C2.40971 14.0783 1.98828 13.0609 1.98828 12V10C1.98828 9.73478 2.09364 9.48043 2.28117 9.29289C2.46871 9.10536 2.72306 9 2.98828 9H6.98828" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      </svg>
                                    ) : null
                                  }


                                </div>
                              </div>
                              <div className="starttext">
                                {planm.plan_name}
                              </div>
                              <div className="priceofplan d-flex align-items-center">
                                <div>${Math.floor(planm.price / 12)}<span className="month">/mo</span>
                                </div>
                                <div className="savesection " >25%save</div>

                              </div>
                              <div className="getbtn" onClick={() => { onslected(planm.plan_id) }} >
                                Get started
                              </div>
                            </div>
                            <div className="poinsshownmain">
                              <div className="pointsshown">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                  <path d="M5.96094 22.0391C4.95469 21.0328 5.62188 18.9219 5.10781 17.6859C4.59375 16.45 2.625 15.3672 2.625 14C2.625 12.6328 4.57188 11.5938 5.10781 10.3141C5.64375 9.03438 4.95469 6.96719 5.96094 5.96094C6.96719 4.95469 9.07813 5.62188 10.3141 5.10781C11.55 4.59375 12.6328 2.625 14 2.625C15.3672 2.625 16.4063 4.57188 17.6859 5.10781C18.9656 5.64375 21.0328 4.95469 22.0391 5.96094C23.0453 6.96719 22.3781 9.07813 22.8922 10.3141C23.4063 11.55 25.375 12.6328 25.375 14C25.375 15.3672 23.4281 16.4063 22.8922 17.6859C22.3563 18.9656 23.0453 21.0328 22.0391 22.0391C21.0328 23.0453 18.9219 22.3781 17.6859 22.8922C16.45 23.4063 15.3672 25.375 14 25.375C12.6328 25.375 11.5938 23.4281 10.3141 22.8922C9.03438 22.3563 6.96719 23.0453 5.96094 22.0391Z" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M18.8125 11.375L12.3922 17.5L9.1875 14.4375" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                {
                                  planm.plan_name === "Starter" ? (
                                    <>
                                      {planm.number_of_users} User Limit
                                    </>
                                  ) : null
                                }
                                {
                                  planm.plan_name === "Business" ? (
                                    <>
                                      Up to {planm.number_of_users} Users
                                    </>
                                  ) : null
                                }
                                {
                                  planm.plan_name === "Enterprise" ? (
                                    <>
                                      Unlimited Users + Everything in Business
                                    </>
                                  ) : null
                                }
                              </div>
                              <div className="pointsshown">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                  <path d="M5.96094 22.0391C4.95469 21.0328 5.62188 18.9219 5.10781 17.6859C4.59375 16.45 2.625 15.3672 2.625 14C2.625 12.6328 4.57188 11.5938 5.10781 10.3141C5.64375 9.03438 4.95469 6.96719 5.96094 5.96094C6.96719 4.95469 9.07813 5.62188 10.3141 5.10781C11.55 4.59375 12.6328 2.625 14 2.625C15.3672 2.625 16.4063 4.57188 17.6859 5.10781C18.9656 5.64375 21.0328 4.95469 22.0391 5.96094C23.0453 6.96719 22.3781 9.07813 22.8922 10.3141C23.4063 11.55 25.375 12.6328 25.375 14C25.375 15.3672 23.4281 16.4063 22.8922 17.6859C22.3563 18.9656 23.0453 21.0328 22.0391 22.0391C21.0328 23.0453 18.9219 22.3781 17.6859 22.8922C16.45 23.4063 15.3672 25.375 14 25.375C12.6328 25.375 11.5938 23.4281 10.3141 22.8922C9.03438 22.3563 6.96719 23.0453 5.96094 22.0391Z" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M18.8125 11.375L12.3922 17.5L9.1875 14.4375" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg> {planm.deep_dive_reports === -1 ? "Deep Dive & Chameleon Carrier (Unlimited)" : (<> Deep Dive {planm.deep_dive_reports} Reports per month</>)}

                              </div>
                              <div className="pointsshown">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                  <path d="M5.96094 22.0391C4.95469 21.0328 5.62188 18.9219 5.10781 17.6859C4.59375 16.45 2.625 15.3672 2.625 14C2.625 12.6328 4.57188 11.5938 5.10781 10.3141C5.64375 9.03438 4.95469 6.96719 5.96094 5.96094C6.96719 4.95469 9.07813 5.62188 10.3141 5.10781C11.55 4.59375 12.6328 2.625 14 2.625C15.3672 2.625 16.4063 4.57188 17.6859 5.10781C18.9656 5.64375 21.0328 4.95469 22.0391 5.96094C23.0453 6.96719 22.3781 9.07813 22.8922 10.3141C23.4063 11.55 25.375 12.6328 25.375 14C25.375 15.3672 23.4281 16.4063 22.8922 17.6859C22.3563 18.9656 23.0453 21.0328 22.0391 22.0391C21.0328 23.0453 18.9219 22.3781 17.6859 22.8922C16.45 23.4063 15.3672 25.375 14 25.375C12.6328 25.375 11.5938 23.4281 10.3141 22.8922C9.03438 22.3563 6.96719 23.0453 5.96094 22.0391Z" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M18.8125 11.375L12.3922 17.5L9.1875 14.4375" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg> {planm.chameleon_carrier_searches === -1 ? "Leads Database (Nationwide) " : (<>Chameleon Carrier  {planm.chameleon_carrier_searches} Searches per month</>)}
                              </div>
                              <div className="pointsshown">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                  <path d="M5.96094 22.0391C4.95469 21.0328 5.62188 18.9219 5.10781 17.6859C4.59375 16.45 2.625 15.3672 2.625 14C2.625 12.6328 4.57188 11.5938 5.10781 10.3141C5.64375 9.03438 4.95469 6.96719 5.96094 5.96094C6.96719 4.95469 9.07813 5.62188 10.3141 5.10781C11.55 4.59375 12.6328 2.625 14 2.625C15.3672 2.625 16.4063 4.57188 17.6859 5.10781C18.9656 5.64375 21.0328 4.95469 22.0391 5.96094C23.0453 6.96719 22.3781 9.07813 22.8922 10.3141C23.4063 11.55 25.375 12.6328 25.375 14C25.375 15.3672 23.4281 16.4063 22.8922 17.6859C22.3563 18.9656 23.0453 21.0328 22.0391 22.0391C21.0328 23.0453 18.9219 22.3781 17.6859 22.8922C16.45 23.4063 15.3672 25.375 14 25.375C12.6328 25.375 11.5938 23.4281 10.3141 22.8922C9.03438 22.3563 6.96719 23.0453 5.96094 22.0391Z" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M18.8125 11.375L12.3922 17.5L9.1875 14.4375" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg> {planm.number_of_states === -1 ? "Insurance Carrier Database (Nationwide)" : (<> Leads Database  {planm.number_of_states} states</>)}
                              </div>

                            </div>

                            {
  planm.plan_name === 'Starter' ?(
    <div className="undertext ">* {planm.free_trial_days} Day Free Trial Of Enterprise Plan</div>

  ):(
    <div className="undertext">* {planm.free_trial_days} Day Free Trial</div>

  )
}
                          </div>
                        </div>
                      </>

                    )
                  })
                }

              </Slider>
            </div>)
        )}
      </div>
    </>
  );
}

export default Carouselhome;
